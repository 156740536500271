<template>
  <div class="chartBox">
    <dv-border-box-1 :dur="15" style="width: 100%;height: 100%;">
      <div class="bg-color-black pdng18">
        <div class="d-flex pt-2 pl-2 flexStart">
          <div class="flexStart">
            <span class="fs-xl text mx-2 chartTitle" style="margin-right: 10px;">热门岗位/人</span>
            <dv-decoration-1 style="width:30%;height:1rem;"/>
          </div>
        </div>
        <div class="d-flex jc-center body-box feeContainer"
             style="width:100%;height:calc(100% - 30px);margin-top: 10px;">
          <div id="right_02" style="width: 100%; height: 100%;"></div>

        </div>
      </div>
    </dv-border-box-1>
  </div>
</template>
<script>
import echartMixins from "../js/resizeMixins";

export default {
  data() {
    return {};
  },
  watch:{
    list(val, oldVal){//普通的watch监听
      this.draw('right_02')
    },
  },
  props: {
    list: {
      type: Array,
      default: [[], []],
    },
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    setTimeout(function () {
      _this.draw('right_02')
    }, 200)
  },
  methods: {
    draw(name) {
      let _this = this
      let myChart = this.$echarts.init(document.getElementById(name));
      myChart.clear();

      let option = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: _this.list[0],


          axisLine: {
            lineStyle: {
              color: "#B4B4B4"
            }
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            interval: 0,
            // rotate: 60,
          },


        },
        yAxis: [
          {
            type: 'value',
            splitLine: {show: false},
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },

            axisLabel: {
              formatter: "{value} "
            }
          },
        ],

        series: [
          {
            name:'人数',
            lineStyle: {
              color: '#E6307B',
              width: 5
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#E6307B'
                },
                {
                  offset: 1,
                  color: '#0C69CA00'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: _this.list[1],

            itemStyle: {
              normal: {
                color: "#F02FC2"
              }
            },
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            type: 'line',
            smooth: true,
          },
        ]
      };
      myChart.setOption(option);

    },
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>
<style lang="less" type="text/less">
.feeContainer {
  .dv-capsule-chart {
    padding-bottom: 0;

    .capsule-container {
      flex: 0 0 60%;
    }
  }

  .unit-label {
    div {
      color: #0d1224;

    }
  }

  .unit-text {
    color: #0d1224;
    //color: pink;
  }
}
</style>
<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;
}
</style>
