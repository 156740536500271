import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=278e800a&"
import script from "./TopBar.vue?vue&type=script&lang=js&"
export * from "./TopBar.vue?vue&type=script&lang=js&"
import style0 from "./TopBar.vue?vue&type=style&index=0&scope=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports