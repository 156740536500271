<template>
  <div class="chartBox flexCenter">
    <div class="d-flex jc-center body-box feeContainer" style="width:100%;height:55%;">
      <div class="flexCenter" style="width:100%;height: 100%;">
        <div style="color: #AADDFF;font-size: 16px;margin-right: 20px;">问卷发布次数</div>
        <dv-border-box-10 style="width: 45%;height: 100%;">
          <div class="flexCenter" style="width: 100%;height: 100%;font-size: 30px;">{{ data }}</div>
        </dv-border-box-10>
      </div>
    </div>
  </div>
</template>
<script>
import echartMixins from "../js/resizeMixins";

export default {
  data() {
    return {};
  },
  props: {
    data: {
      type: String,
      default: 0,
    },
  },
  mixins: [echartMixins],
  mounted() {
  },
  methods: {},
  destroyed() {
    window.onresize = null;
  }
};
</script>
<style lang="less" type="text/less">
.feeContainer {
  .dv-capsule-chart {
    padding-bottom: 0;

    .capsule-container {
      flex: 0 0 60%;
    }
  }

  .unit-label {
    div {
      color: #0d1224;

    }
  }

  .unit-text {
    color: #0d1224;
    //color: pink;
  }
}
</style>
<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;
}
</style>
