<template>
  <div class="chartBox">
    <dv-border-box-1 class="" style="width: 100%;height: 100%;">
      <div class="bg-color-black pdng18 flexCloumnBetween" style="background-color: #3373F621!important">
        <div style="width: 100%;height: 100%;">
          <div class="flexCloumn" v-for="(item,index) in list" :key="index" style="width: 100%;height: 30%;">
            <div class="chartTitle">{{item.name}}</div>
            <div class="d-flex jc-center feeContainer flexBetween flex_1" style="width: 100%;">
              <div class="flexBetween" style="position: relative;width: calc( 100% - 50px );height: 30px;">
                <div class="flexStartUp" style="position: absolute;width: 100%;height: 100%;z-index: 3;top: 0;">
                  <img class="" ref="imgIcon" src="../../../../public/img/home_left_01_icon1.png" alt="" style="max-width: 100%;max-height: 100%">
                </div>

                <div class="flexStartUp" :style="'width:'+ imgw +'px'" style="position: absolute;z-index: 2;top: 0;">
                  <div :style="'width:'+ item.width + ';height:' + imgh+'px'">
                    <div class="waves" :style="'background-color:'+item.color + ';height:' + imgh+'px' + ';width:' + '100%'"></div>
                  </div>
                </div>

                <div class="flexStartUp" :style="'width:'+ imgw +'px'" style="position: absolute;z-index: 1;top: 0;">
                  <div :style="'height:' + imgh+'px'" style="width: 100%;background-color:#ccc;"></div>
                </div>


              </div>
              <div class="chartTitle flex_1 flexEnd" :style="'color:'+item.color">{{item.data}}</div>
            </div>
          </div>
        </div>




      </div>
    </dv-border-box-1>
  </div>
</template>
<script>
import echartMixins from "../js/resizeMixins";

export default {
  data() {
    return {
      imgh:0,
      imgw:0
    };
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    setTimeout(function (){
      if( _this.$refs.imgIcon&& _this.$refs.imgIcon.length>0){
        let ih = _this.$refs.imgIcon[0].offsetHeight;
        let iw = _this.$refs.imgIcon[0].offsetWidth;
        _this.imgh = ih
        _this.imgw = iw-1
      }else {
        let ih = _this.$refs.imgIcon.offsetHeight;
        let iw = _this.$refs.imgIcon[0].offsetWidth;
        _this.imgh = ih
        _this.imgw = iw-1
      }

    },500)

  },
  methods: {},
  destroyed() {
    window.onresize = null;
  }
};
</script>
<style lang="less" type="text/less">
.feeContainer {
  .dv-capsule-chart {
    padding-bottom: 0;

    .capsule-container {
      flex: 0 0 60%;
    }
  }

  .unit-label {
    div {
      color: #0d1224;

    }
  }

  .unit-text {
    color: #0d1224;
    //color: pink;
  }
}
</style>
<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;
  .waves{
    animation-name:waves1;
    animation-duration:2.5s;
    animation-timing-function:linear;
    animation-delay:4s;
    animation-iteration-count:infinite;
    animation-direction:normal;
  }
  @keyframes waves1 {
    0% {
      width: 0px;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 100%;
    }
  }
}
</style>
