<template>
  <div class="chartBox">
    <div class="d-flex jc-center body-box flexBetween"
         @mouseover="handleIn" @mouseleave="handleOut"
         style="width:100%;height:100%;position:relative; overflow: unset">
      <div v-if="false" class="flexCenter" @click="tabLeft"
           style="width:45px;height: 80%;position: absolute;left: 0;cursor: pointer;">
        <img src="../../../../public/img/home_middle02_left.png" alt="" style="width: 100%">
      </div>
      <div v-if="false" class="flexCenter" @click="tabRight"
           style="width:45px;height: 80%;position: absolute;right: 0;cursor: pointer;">
        <img src="../../../../public/img/home_middle02_right.png" alt="" style="width: 100%">
      </div>


      <!-- 3d照片墙 -->
      <!--      <div v-if="true" style="width: calc( 100% - 100px );height: 50%;border: 1px solid pink;position: relative">-->
      <!--        <div class="demo-perspective-photo">-->
      <!--          <div id="stage">-->
      <!--            <div id="container">-->
      <!--              &lt;!&ndash; 此处使用div代替 &ndash;&gt;-->
      <!--              <div class='example' style=""></div>-->
      <!--              <div class='example' style=""></div>-->
      <!--              <div class='example' style=""></div>-->
      <!--              <div class='example' style=""></div>-->
      <!--              <div class='example' style=""></div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--      </div>-->


      <div id="wrapper">
        <div class="demo-perspective-photo1">
          <div id="stage">
            <div :class="tabclass" id="container">
              <div class='example' v-for="(item,index) in piclist" :key="index">
                <div style="width: 100%;height: 100%;position: relative;cursor: pointer" @click="linkPage(item.url)">
                  <div class="flexCloumnCenter" style="width: 100%;height: 100%;position: absolute;z-index: 2;justify-content: center;">
                    <div style="font-size: 12px;transform: scale(0.8)">{{ item.name }}</div>
                    <div style="font-size: 12px;font-weight: 700;transform: scale(0.8)">{{ item.value }}</div>
                  </div>
                  <div class="bg_shadow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <transition-group v-if="false" name="list" tag="ul" mode="out-in" style="width: 100%;height: 100%;">
          <li class="transItem" v-for="(item,index) in piclist" :key="index" :style="config[index]" :class="item.class">
            <div class="flexCloumnCenter" style="width: 100%;height: 100%;justify-content: center;position: relative">
              <div style="font-size: 16px;">
                {{ item.name }}
              </div>
              <div style="font-size: 18px;font-weight: 700">
                {{ item.value }}
              </div>
              <div class="bg_shaow"
                   style="width: 100%;height: 100%;position: absolute;z-index:-1;top:-3px;left: 3px;background-color:#37E2F681;"></div>

            </div>

          </li>
        </transition-group>

        <div class="robot flexCenter">
          <img src="../../../../public/img/home_middle_02_robot1.png" alt="">
        </div>

        <div v-if="false" class="lianyi">1212112</div>
      </div>


    </div>
  </div>
</template>
<script>
import echartMixins from "../js/resizeMixins";

export default {
  data() {
    return {
      active: 2,
      list: [{}, {}, {}, {}, {}],
      piclist: [],
      //文件图片配置
      config: [
        {
          position: "absolute",
          width: '30%',
          height: '30%',
          top: "20%",
          left: "0px",
          opacity: 0.5,
          zIndex: 2,
          transition: "1s",
          border: '1px solid #00FFFF',
          color: '#00FFFF',
          backgroundColor: '#AADDFF51',
          // perspective: '800px',
          // transformStyle: 'preserve-3d',
          // transform: 'translate3d(-15deg,30deg,-5deg)',
          // transform: 'rotatex(-15deg) rotatey(30deg) rotatez(-5deg)',
          // transition: 'opacity 1s, transform 1s'
          transform: 'rotateY(60deg) ',
        },
        {
          position: "absolute",
          width: '30%',
          height: '30%',
          top: "10%",
          left: "10%",
          opacity: 0.5,
          zIndex: 2,
          transition: "1s",
          border: '1px solid #00FFFF',
          color: '#00FFFF',
          backgroundColor: '#AADDFF51',
          // transform: 'rotatex(-15deg) rotatey(30deg) rotatez(-5deg)',
          transform: 'rotateY(-60deg) translateZ(98px)',
        },
        {
          position: "absolute",
          width: '30%',
          height: '30%',
          top: "0px",
          left: "35%",
          opacity: 1,
          zIndex: 4,
          transition: "1s",
          border: '1px solid #00FFFF',
          color: '#00FFFF',
          backgroundColor: '#37E2F681',
        },
        {
          position: "absolute",
          width: '30%',
          height: '30%',
          top: "10%",
          left: "calc( 100% - 40% )",
          opacity: 0.5,
          zIndex: 2,
          transition: "1s",
          border: '1px solid #00FFFF',
          color: '#00FFFF',
          backgroundColor: '#AADDFF51',
          transform: 'rotateY(60deg) translateZ(98px)',
        },
        {
          position: "absolute",
          width: '30%',
          height: '30%',
          top: "20%",
          left: "calc( 100% - 30% )",
          opacity: 0.5,
          zIndex: 2,
          transition: "1s",
          border: '1px solid #00FFFF',
          color: '#00FFFF',
          backgroundColor: '#AADDFF51',
          transform: 'rotateY(60deg) translateZ(98px)',
        }
      ],
      previous: 0,
      now: Date.now(),
      timer: "",
      tabclass:'ani_trans'
    };
  },
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  mixins: [echartMixins],
  mounted() {
    // console.log(this.data)
    let _this = this
    setTimeout(function () {
      _this.list = JSON.parse(JSON.stringify(_this.data))
      let arr = JSON.parse(JSON.stringify(_this.data))
      _this.piclist = arr
    }, 200)
    this.timer = setInterval(function () {
      // _this.tabRight()
    }, 2000)
  },
  beforeDestroy() {
    //清除定时器
    clearInterval(this.timer);
    // console.log("beforeDestroy");
  },
  destroyed() {
    //清除定时器
    clearInterval(this.timer);
    // console.log("destroyed");
    window.onresize = null;
  },
  methods: {
    linkPage(url){
      console.log(204,url)
      if(url&&url!=''&&url!=undefined){
        this.$router.push(url)

      }
    },

    handleIn(){
      // console.log('handleIn')
      this.tabclass = 'ani_trans ani_paused'
    },

    handleOut(){
      // console.log('handleOut')
      this.tabclass = 'ani_trans ani_active'

    },
    tabLeft() {
      this.now = Date.now();
      if (this.now - this.previous > 1000) {
        this.config.push(this.config.shift());
        this.previous = this.now;
      }

      // let list = JSON.parse(JSON.stringify(this.list))
      // let temp = JSON.parse(JSON.stringify(this.list))
      // temp.unshift(temp[4])
      // temp.pop()
      // this.list = temp
    },
    tabRight() {
      // let _this = this
      // this.tabclass = 'ani_trans_once'
      // setTimeout(function (){
      //   _this.tabclass = ''
      // },500)



      // this.now = Date.now();
      // if (this.now - this.previous > 1000) {
      //   this.config.unshift(this.config.pop());
      //   this.previous = this.now;
      // }

      // let temp = JSON.parse(JSON.stringify(this.piclist))
      // temp.push(temp[0])
      // temp.shift()
      // this.piclist = temp
    }
  },

};
</script>
<style scope lang="less" type="text/less">
@import "./css/reset.css";
//@import "./css/style.css";

#wrapper {
  margin: 0 auto;
  width: calc(100% - 100px);
  height: 100%;
  position: relative;

  .li01 {
    .bg_shaow {
      left: -5px !important;
    }
  }

  .li02 {
    .bg_shaow {
      left: -5px !important;
    }
  }

  .li03 {
    .bg_shaow {
      opacity: 0.4;
    }
  }


  .demo-perspective-photo1 {
    position: absolute;z-index: 10;
    width: 100%;
    height: 100%;
    top: 0px;
    zoom: 2;
    //border: 1px solid blue;

    #stage {
      width: 100%;
      height: 60%;
      -webkit-perspective: 800px;
      -moz-perspective: 800px;
      perspective: 800px;
      -webkit-transition: top 0.5s;
      -moz-transition: top 0.5s;
      transition: top 0.5s;
      position: relative;
      top: 30px;
    }

    #container {
      box-sizing: border-box;
      position: absolute;
      width: 40%;
      height: 50%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      //cursor: pointer;
      -webkit-transition: -webkit-transform 1s;
      -moz-transition: -moz-transform 1s;
      transition: transform 1s;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
    .ani_trans{
      -webkit-animation: photoRotate1 18s ease-in-out infinite 2s;
      animation: photoRotate1 18s ease-in-out infinite 2s;
    }
    .ani_trans_once{
      -webkit-animation: photoRotate1 18s ease-in-out infinite 2s;
      animation: photoRotate1 18s ease-in-out normal 2s;
    }
    .ani_active {
      animation-play-state: running;
    }
    .ani_paused {
      animation-play-state: paused;
    }
    .example {
      width: 100%;
      height: 100%;
      //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
      -webkit-transition: opacity 1s, -webkit-transform 1s;
      -moz-transition: opacity 1s, -moz-transform 1s;
      transition: opacity 1s, transform 1s;
      position: absolute;
      bottom: 0;
      zIndex: 2;
      border: 0.5px solid #00FFFF51;
      color: #00FFFF;
      background-color: #AADDFF51;
      border-radius: 2px;
      cursor: default;
      .bg_shadow{
        width: 100%;height: 100%;
        position: absolute;z-index:1;
        top:-2px;left: 2px;
        background-color:#37E2F681;
        border-radius: 2px;
      }
    }

    .example:nth-child(1) {
      -webkit-transform: rotateY(0deg) translateZ(88px);
      transform: rotateY(0deg) translateZ(88px);
    }

    .example:nth-child(2) {
      -webkit-transform: rotateY(60deg) translateZ(88px);
      transform: rotateY(72deg) translateZ(88px);
    }

    .example:nth-child(3) {
      -webkit-transform: rotateY(120deg) translateZ(88px);
      transform: rotateY(144deg) translateZ(88px);
    }

    .example:nth-child(4) {
      -webkit-transform: rotateY(180deg) translateZ(88px);
      transform: rotateY(216deg) translateZ(88px);
    }

    .example:nth-child(5) {
      -webkit-transform: rotateY(240deg) translateZ(88px);
      transform: rotateY(288deg) translateZ(88px);
    }
  }
  @-webkit-keyframes photoRotate1 {
    0% {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    20% {
      -webkit-transform: rotateY(60deg);
      transform: rotateY(72deg);
    }
    40% {
      -webkit-transform: rotateY(120deg);
      transform: rotateY(144deg);
    }
    60% {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(216deg);
    }
    80% {
      -webkit-transform: rotateY(240deg);
      transform: rotateY(288deg);
    }
    100% {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }

  @keyframes photoRotate1 {
    0% {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    20% {
      -webkit-transform: rotateY(60deg);
      transform: rotateY(72deg);
    }
    40% {
      -webkit-transform: rotateY(120deg);
      transform: rotateY(144deg);
    }
    60% {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(216deg);
    }
    80% {
      -webkit-transform: rotateY(240deg);
      transform: rotateY(288deg);
    }
    100% {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }

  .robot {
    width: 100%;
    height: 50%;
    margin: 0 auto;
    position: absolute;  z-index: 2;
    bottom: 0;
    transition: transform 1s;
    animation: transrobot 2s ease-in-out infinite 2s;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    @keyframes transrobot {
      0% {bottom: 0px;}
      50% {bottom: 30px;}
      100% {bottom: 0px;}
    }
  }
  .lianyi{
    width: 100%;
    //height: 50%;
    margin: 0 auto;
    position: absolute;
    z-index: 1;
    bottom: 0;
    text-align: center;
    //transition: transform 1s;
    //animation: transrobot 2s ease-in-out infinite 2s;
  }
}

ul {
  list-style: none;
}

li img {
  height: 500px;
  width: 100%;
}

.prev,
.next {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  top: 50%;
  margin-top: -56px;
  overflow: hidden;
  text-decoration: none;
  background-color: aqua;
  z-index: 5;
  opacity: 1;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}




.chartBox {
  width: 100%;
  height: 100%;

  .posCon {
    position: relative;
    width: calc(100% - 100px);
    height: 50%;
    border: 1px dotted dodgerblue;

    .pos_top {
      width: 100%;
      height: 30%;
      position: absolute;
      margin: 0 auto;

      .item_top {
        width: 30%;
        height: 100%;
        margin: 0 auto;
        border: 1px solid aqua;
      }
    }

    .pos_l1 {
      width: 30%;
      height: 30%;
      position: absolute;
      top: 15%;
      left: 0;

      .item_l1 {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border: 1px solid aqua;
      }
    }

    .pos_l2 {
      width: 30%;
      height: 30%;
      position: absolute;
      top: 60%;
      left: 0;

      .item_l2 {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border: 1px solid aqua;
      }
    }

    .pos_r1 {
      width: 30%;
      height: 30%;
      position: absolute;
      top: 15%;
      right: 0;

      .item_r1 {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border: 1px solid aqua;
      }
    }

    .pos_r2 {
      width: 30%;
      height: 30%;
      position: absolute;
      top: 60%;
      right: 0;

      .item_r2 {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border: 1px solid aqua;
      }
    }
  }
}
</style>
