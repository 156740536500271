<template>
  <div class="chartBox">
    <dv-border-box-1 :dur="15" style="width: 100%;height: 100%;">
      <div class="bg-color-black pdng18">
        <div class="d-flex pt-2 pl-2 flexStart">
          <div class="flexStart">
            <span class="fs-xl text mx-2 chartTitle" style="margin-right: 10px;">就业区域</span>
            <dv-decoration-1 style="width:30%;height:1rem;"/>
          </div>
        </div>
        <div class="d-flex jc-center body-box feeContainer flexStart"
             style="width:100%;height:calc(100% - 30px);margin-top: 10px;">
          <div id="bottomLeftChart" style="width: 30%; height: 100%;"></div>
          <div id="left_03_plan1" style="width: 70%; height: 100%;"></div>

        </div>
      </div>
    </dv-border-box-1>
  </div>
</template>
<script>
import echartMixins from "../js/resizeMixins";

export default {
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  mixins: [echartMixins],
  mounted() {
    console.log(this.list)
    let _this = this
    setTimeout(function () {
      _this.draw('left_03_plan')
    }, 600)
  },
  methods: {
    draw(name) {
      let _this = this
      this.chart = this.$echarts.init(document.getElementById(name));
      let option = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        // color: ['#2251BA', '#DB4166', '#FDFD5A', '#84BA5A', '#84BA5A', '#84BA5A', '#84BA5A'],
        series: [
          {
            type: 'pie',

            center: ['50%', '50%'],
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,

            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: _this.list,
          }
        ]
      };
      this.chart.setOption(option);

    },
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>
<style lang="less" type="text/less">
.feeContainer {
  .dv-capsule-chart {
    padding-bottom: 0;

    .capsule-container {
      flex: 0 0 60%;
    }
  }

  .unit-label {
    div {
      color: #0d1224;

    }
  }

  .unit-text {
    color: #0d1224;
    //color: pink;
  }
}
</style>
<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;
}
</style>
