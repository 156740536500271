<template>
  <div class="homeContainer bg">
<!--    <div class="twinkl"></div>-->

    <!--        <dv-full-screen-container class="bg">-->

    <dv-loading v-if="loading">Loading...</dv-loading>
    <div style="width: 100%; height: 100%;" class="host-body">
      <div class="topBar">
        <TopBar @showPT="showPT" @showPlan="showPlan" @changeClass="changeClass"></TopBar>
      </div>

      <div class="chartContainer">
        <div class="flexBetween" style="width: 100%;height: 100%;">
          <div style="width: 66.5%;height: 100%;">
            <div class="flexStart" style="width: 100%;height: 66%;">
              <div style="width: 50%;height: 100%;">
                <div style="width: 100%;height: 54.5%;">
                  <Left_01 :list="left_01" :isShowPT="isShowPT"></Left_01>
                </div>
                <div style="width: 100%;height: 45.5%;">
                  <Left_02 v-if="isShowPT" :list="left_02" :isShowPT="isShowPT"></Left_02>
                  <Left_02_plan v-else :list="left_02_plan" :isShowPT="isShowPT"></Left_02_plan>
                </div>
              </div>

              <div style="width: 50%;height: 100%;">
                <div style="width: 100%;height: 40%;">
                  <Middle_01 v-if="isShowPT" :data="middle_01"></Middle_01>
                  <Middle_01_plan v-else :middle_01_planL="middle_01_planL" :middle_01_planR="middle_01_planR"></Middle_01_plan>
                </div>
                <div style="width: 100%;height: 60%;">
                  <Middle_02 :data="middle_02"></Middle_02>
                </div>
              </div>
            </div>
            <div style="width: 100%;height: 33%;">
              <Left_03 :left_03_pie="left_03_pie" :left_03_jiangsu="left_03_jiangsu" :left_03_noJiangsu="left_03_noJiangsu"></Left_03>
            </div>
          </div>


          <div style="width: 33%;height: 100%;">
            <div style="width: 100%;height: 35%;">
              <Right_01 :list="right_01"></Right_01>
            </div>

            <div style="width: 100%;height: 30%;">
              <Right_02 v-if="isShowPT" :list="right_02"></Right_02>
              <Right_02_plan v-else :list="right_02_plan"></Right_02_plan>
            </div>

            <div style="width: 100%;height: 35%;">
              <Right_03 v-if="isShowPT" :list="right_03"></Right_03>
              <Right_03_plan v-else :list="right_03_plan"></Right_03_plan>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--        </dv-full-screen-container>-->
  </div>
</template>

<script>
import 'echarts/map/js/province/jiangsu'
import TopBar from "./component/TopBar";
import Left_01 from "./component/Left_01";

import Left_02 from "./component/Left_02";
import Left_02_plan from "./component/Left_02_plan";

import Left_03 from "./component/Left_03";
import Left_03_plan from "./component/Left_03_plan";

import Middle_01 from "./component/Middle_01";
import Middle_01_plan from "./component/Middle_01_plan";
import Middle_02 from "./component/Middle_02";
import Right_01 from "./component/Right_01";
import Right_02 from "./component/Right_02";
import Right_02_plan from "./component/Right_02_plan";

import Right_03 from "./component/Right_03";
import Right_03_plan from "./component/Right_03_plan";


export default {
  components: {
    TopBar,
    Left_01,
    Left_02, Left_02_plan,
    Left_03, Left_03_plan,
    Middle_01, Middle_01_plan, Middle_02,
    Right_01,
    Right_02,Right_02_plan,
    Right_03,Right_03_plan,

  },
  data() {
    return {
      loading: false,
      pmid: 1,
      left_01: [
        {data: 0, name: '平台总人数/人', color: '#CFA162', width: ''},
        {data: 0, name: '男生人数/人', color: '#4366F3', width: ''},
        {data: 0, name: '女生人数/人', color: '#F9026F', width: ''},
      ],
      left_02: [],
      middle_01: '0',
      middle_01_planL: '0',
      middle_01_planR: '0',

      middle_02: [
        {class: 'li01', name: '班级相册', value: '',url:'/classPhoto'},
        {class: 'li02', name: '校园风景', value: '',url:'/campus'},
        {class: 'li03', name: '问卷发布次数', value: '',url:'/plan'},
        {class: 'li04', name: '招聘信息', value: '',url:''},
        {class: 'li05', name: '通知公告', value: '',url:'/news1'},
      ],
      right_01: [],
      right_02: [],
      right_02_plan:[],
      right_03: [],
      right_03_plan:[],
      isShowPT:false,
      left_03_pie: [],
      left_03_jiangsu:[],
      left_03_noJiangsu:[],
      left_02_plan: [],
      planid:null,
      classid:null,
    }
  },
  mounted() {
    this.getData()
    this.getPlan()
  },
  methods: {
    showPT(){
      this.isShowPT = true
      this.left_01 = [
        {data: 0, name: '平台总人数/人', color: '#CFA162', width: ''},
        {data: 0, name: '男生人数/人', color: '#4366F3', width: ''},
        {data: 0, name: '女生人数/人', color: '#F9026F', width: ''},
      ]
      this.left_02 = []
      this.left_02_plan = []
      this.left_03_pie = []
      this.left_03_jiangsu = []
      this.left_03_noJiangsu = []
      this.middle_01 = '0'
      this.middle_01_planL = '0'
      this.middle_01_planR = '0'
      this.middle_02 = [
        {class: 'li01', name: '班级相册', value: '',url:'/classPhoto'},
        {class: 'li02', name: '校园风景', value: '',url:'/campus'},
        {class: 'li03', name: '问卷发布次数', value: '',url:'/plan'},
        {class: 'li04', name: '招聘信息', value: '',url:''},
        {class: 'li05', name: '通知公告', value: '',url:'/news1'},
      ]
      this.right_01 = []
      this.right_02 = []
      this.right_02_plan = []
      this.right_03 = []
      this.right_03_plan = []
      this.getData()
      this.getData_alumni()
    },
    showPlan(e){
      this.isShowPT = false
      this.planid = e ? e : this.planid

      this.left_01 = [
        {data: 0, name: '平台总人数/人', color: '#CFA162', width: ''},
        {data: 0, name: '男生人数/人', color: '#4366F3', width: ''},
        {data: 0, name: '女生人数/人', color: '#F9026F', width: ''},
      ]
      this.left_02 = []
      this.left_02_plan = []
      this.left_03_pie = []
      this.left_03_jiangsu = []
      this.left_03_noJiangsu = []
      this.middle_01 = '0'
      this.middle_01_planL = '0'
      this.middle_01_planR = '0'
      this.middle_02 = [
        {class: 'li01', name: '班级相册', value: '',url:'/classPhoto'},
        {class: 'li02', name: '校园风景', value: '',url:'/campus'},
        {class: 'li03', name: '问卷发布次数', value: '',url:'/plan'},
        {class: 'li04', name: '招聘信息', value: '',url:''},
        {class: 'li05', name: '通知公告', value: '',url:'/news1'},
      ]
      this.right_01 = []
      this.right_02 = []
      this.right_02_plan = []
      this.right_03 = []
      this.right_03_plan = []
      this.getData_alumni()
      this.getData_plan()
      this.getjobs()
    },
    getPlan() {
      this.planList = []
      this.$http.post("api/by_ivst_plan_list", {page: 1, pagesize: 1000000,}).then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.showPlan(res.data.data[0].id)
        }else{
          this.showPT()
        }
      });
    },
    getData() {
      let _this = this
      return new Promise(resolve => {
        this.$http.post('api/home_statistical').then(res => {
          if (res.data.code == 200) {
            let rData = res.data.data
            this.middle_02[0].value = rData.banji.count + `张`
            this.middle_02[1].value = rData.xiaoyuan.count + `张`
            this.middle_02[2].value = rData.wenjuan.count + `次`
            this.middle_02[3].value = rData.zhaopin.count + `条`
            this.middle_02[4].value = rData.gonggao.count + `条`
            _this.middle_01 = rData.wenjuan.count + `次`
          }
          resolve()
          this.loading = false
        })
      })
    },
    getData_alumni() {
      let _this = this
      return new Promise(resolve => {
        this.$http.post('api/alumni_statistics', {planid: _this.planid, classid: _this.classid}).then(res => {
          if (res.data.code == 200) {
            let rdata = res.data.data
            if (rdata.xingbie&&rdata.xingbie.length>0) {
              let xingbie = rdata.xingbie
              let obj = Object.assign({},xingbie[0],xingbie[1])
              let total = Number(obj['1']) + Number(obj['2'])
              let female = Number(obj['1'])
              let male = Number(obj['2'])
              let rate2 = 0, rate1 = 0
              if (female == 0) {
                rate2 = 0
              } else {
                rate2 = female / total;
              }
              if (female == 0) {
                rate1 = 0
              } else {
                rate1 = male / total;
              }
              this.middle_01_planL = total+ `人`
              this.left_01[0].data = total
              this.left_01[1].data = female
              this.left_01[2].data = male

              this.left_01[0].width = '100%'
              this.left_01[1].width = Math.round(rate2 * 100) + '%'
              this.left_01[2].width = Math.round(rate1 * 100) + '%'
              if(_this.isShowPT){
                this.left_01[0].name = '平台总人数/人'
              }else{
                this.left_01[0].name = '问卷参与人数/人'

              }
              this.left_01 = [..._this.left_01]
            }else{
              this.middle_01_planL = 0+ `人`
              this.left_01[0].data = 0
              this.left_01[1].data = 0
              this.left_01[2].data = 0

              this.left_01[0].width = '0%'
              this.left_01[1].width = '0%'
              this.left_01[2].width = '0%'
              if(_this.isShowPT){
                this.left_01[0].name = '平台总人数/人'
              }else{
                this.left_01[0].name = '问卷参与人数/人'
              }
              this.left_01 = [..._this.left_01]
            }
            if (rdata.xingzhi&&rdata.xingzhi.length>0) {
              let arr = []
              for (let i in rdata.xingzhi) {
                let obj = {}
                for (let a in rdata.xingzhi[i]) {
                  obj = {
                    value: rdata.xingzhi[i][a],
                    name: a
                  }
                }
                arr.push(obj)
              }
              this.right_03 = arr
            }else {
              this.right_03 = []
            }
            if (rdata.minzhu) {
              let arr1 = [], arr2 = []
              for (let i in rdata.minzhu) {
                for (let a in rdata.minzhu[i]) {
                  arr1.push(a)
                  arr2.push(rdata.minzhu[i][a])
                }
              }
              this.right_02 = [arr1, arr2]
            }
            if (rdata.xinzi) {
              let arr = []
              for (let i in rdata.xinzi) {
                let obj = {}
                for (let a in rdata.xinzi[i]) {
                  obj = {
                    value: rdata.xinzi[i][a],
                    name: a
                  }
                }
                arr.push(obj)
              }
              let total = 0
              arr.map(a => {
                total += Number(a.value)
              })
              arr.map(a => {
                if (a.value == 0) {
                  a.rate = 0
                } else {
                  let rate = a.value / total;
                  a.rate = Math.round(rate * 100)
                  a.rateLabel = Math.round(rate * 100) + '%'
                }
              })
              this.right_01 = arr
            }
            if (rdata.zhengzhi) {
              let arr = []
              for (let i in rdata.zhengzhi) {
                let obj = {}
                for (let a in rdata.zhengzhi[i]) {
                  obj = {
                    value: rdata.zhengzhi[i][a],
                    name: a
                  }
                }
                arr.push(obj)
              }
              let total = 0
              arr.map(a => {
                total += Number(a.value)
              })
              arr.map(a => {
                if (a.value == 0) {
                  a.rate = 0
                } else {
                  let rate = total?a.value / total:0;
                  a.rate = Math.round(rate * 100)
                  a.rateLabel = Math.round(rate * 100) + '%'
                }
              })

              let fun = function (num) {
                if (num > 3) {
                  num -= 3
                  fun()
                } else {
                  return num
                }
              }
              arr.map((a, ia) => {
                let colorsList = [['#76E4FB', '#4366F3'], ['#81D59E', '#319B88'], ['#F7FFA0', '#FFAD56'],]
                let obj = {data: [a.rate], shape: 'round', colors: ['#76E4FB', '#4366F3'], name: a.name}
                let index = fun(ia)
                obj.colors = colorsList[index]

                this.left_02[ia] = obj
              })
            }
            if (rdata.chengshi) {
              let obj0 = {
                value: rdata.chengshi[0]['省内'],
                name: '本地就业'
              },obj1 = {value: rdata.chengshi[1]['省外'],
                name: '外地就业'}

              let pie = [obj0,obj1]
              this.left_03_pie = pie

              let arr = [],arr1=[]
              if(rdata.chengshi[2]['省内Array']&&rdata.chengshi[2]['省内Array'].length>0){
                for (let i in rdata.chengshi[2]['省内Array']) {
                  let obj = {}
                  for (let a in rdata.chengshi[2]['省内Array'][i]) {
                    let value = rdata.chengshi[2]['省内Array'][i][a]
                    if (a.indexOf('"') != -1) {
                      a = a.replace('"', "")
                    }
                    if ((a.substr(a.length - 1, 1)).indexOf('"') != -1) {
                      a = a.replace('"', "")
                    }
                    obj = {
                      zs_count: value,
                      area_name: a
                    }
                  }
                  arr.push(obj)
                }
                this.left_03_jiangsu = arr
              }else {
                this.left_03_jiangsu = []
              }
              if(rdata.chengshi[2]['省内Array']&&rdata.chengshi[2]['省内Array'].length>0){
                for (let i in rdata.chengshi[3]['省外Array']) {
                  let obj = {}
                  for (let a in rdata.chengshi[3]['省外Array'][i]) {
                    let value = rdata.chengshi[3]['省外Array'][i][a]
                    if (a.indexOf('"') != -1) {
                      a = a.replace('"', "")
                    }
                    if ((a.substr(a.length - 1, 1)).indexOf('"') != -1) {
                      a = a.replace('"', "")
                    }
                    obj = {
                      zs_count: value,
                      area_name: a
                    }
                  }
                  arr1.push(obj)
                }
                this.left_03_noJiangsu = arr1
              }else {
                this.left_03_noJiangsu = []
              }
            }
          }
          resolve()
          this.loading = false
        })
      })
    },
    getData_plan() {
      let _this = this
      this.$http.post('api/get_ivst_tj_tm', {plan_id: _this.planid, classid: _this.classid}).then(res => {
        if (res.data&&res.data.length>0) {
          let rdata = res.data
          rdata.map(tm=>{
            if(tm.tm_title.indexOf( '工作岗位满意')!=-1){
              // middle_01_planR
              let xx = tm.tm_content
              let arr = []
              if (xx&&xx.length>0) {

                let count = 0,total = 0
                xx.map(option=>{
                  total+=option.choose_count
                  if(option.title=='非常满意'||option.title=='满意'){
                    count+=option.choose_count
                  }
                })
                let rate = total?count / total:0;
                let rateLabel = Math.round(rate * 100) + '%'
              _this.middle_01_planR = rateLabel
              }
            }

            if(tm.tm_title.indexOf( '专业是否对口')!=-1){
              let xx = tm.tm_content
              let arr = []
              if (xx&&xx.length>0) {
                xx.map(option=>{
                  let  obj = {
                    value: option.choose_count,
                    name: option.title
                  }
                  arr.push(obj)
                })
                let total = 0
                arr.map(a => {
                  total += Number(a.value)
                })
                arr.map(a => {
                  if (a.value == 0) {
                    a.rate = 0
                  } else {
                    let rate = a.value / total;
                    a.rate = Math.round(rate * 100)
                    a.rateLabel = Math.round(rate * 100) + '%'
                  }
                })
                let fun = function (num) {
                  if (num > 3) {
                    num -= 3
                    fun()
                  } else {
                    return num
                  }
                }
                arr.map((a, ia) => {
                  let colorsList = [['#76E4FB', '#4366F3'], ['#81D59E', '#319B88'], ['#F7FFA0', '#FFAD56'],]
                  let obj = {data: [a.rate], shape: 'round', colors: ['#76E4FB', '#4366F3'], name: a.name}
                  let index = fun(ia)
                  obj.colors = colorsList[index]

                  this.left_02_plan[ia] = obj
                })
              }
            }

            if(tm.tm_title.indexOf( '教学情况满意度')!=-1){
              let xx = tm.tm_content
              let arr = []
              if (xx&&xx.length>0) {
                xx.map(option=>{
                  let  obj = {
                    value: option.choose_count,
                    name: option.title
                  }
                  arr.push(obj)
                })
                let total = 0
                arr.map(a => {
                  total += Number(a.value)
                })
                arr.map(a => {
                  if (a.value == 0) {
                    a.rate = 0
                  } else {
                    let rate = a.value / total;
                    a.rate = Math.round(rate * 100)
                    a.rateLabel = Math.round(rate * 100) + '%'
                  }
                })
                let fun = function (num) {
                  if (num > 3) {
                    num -= 3
                    fun()
                  } else {
                    return num
                  }
                }
                arr.map((a, ia) => {
                  let colorsList = [['#76E4FB', '#4366F3'], ['#81D59E', '#319B88'], ['#F7FFA0', '#FFAD56'],]
                  let obj = {data: [a.rate], shape: 'round', colors: ['#76E4FB', '#4366F3'], name: a.name}
                  let index = fun(ia)
                  obj.colors = colorsList[index]

                  this.right_02_plan[ia] = obj
                })
              }
            }
          })
        }
        this.loading = false
      })

    },
    getjobs(){
      this.$http.post('api/job_type_ranking', {plan_id: this.planid, classid: this.classid}).then(res => {
        if (res.data&&res.data.length>0) {
          let rdata = res.data
          let arr1 = [],arr2 = []
          rdata.map(a=>{
            arr1.push(a.type_name)
            arr2.push(a.count)
          })
          this.right_03_plan = [arr1,arr2]
        }else {
          this.right_03_plan = [[],[]]
        }
      })
    },
    changeClass(e){
      this.classid = e
      this.showPlan()
    }
  },
}

</script>
<style>
.testpink{
  border: 1px solid pink;
}
.chartBox .bg-color-black {
  height: 100%;
}
</style>
<style scope lang="less" type="text/less">
@import "../../../public/css/home";

.homeContainer {
  div {
    box-sizing: border-box;
  }

  .pdng10 {
    padding: 10px;
  }
  .pdng18 {
    padding: 18px;
  }
  .pdng_rem_dot2 {
    padding: 0.2rem;
  }

  .border {
    //border: 0.5px solid #1a5cd7;
    border: 0!important;
    border-radius: 16px;
  }

  .topBar {
    width: 100%;
    height: 9.2%;
    box-sizing: border-box;
  }

  .chartContainer {
    width: 100%;
    height: 86.8%;
    box-sizing: border-box;
    margin-top: 1%;
  }

  .chartTitle {
    font-size: 16px;
    font-weight: 700;
    background-image: linear-gradient(0deg, #3A80F9, #5CC2E0);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }


  .twinkl {
    position:absolute;z-index: -1;
    top:15%;
    left:0;
    right:0;
    bottom:0;
    width:50%;
    height:40%;
    display:block;
    margin-left: auto;
    margin-right: auto;
  }
  .twinkl{
    background:transparent url("../../../public/img/twinkling.png") repeat top center;
    z-index:0;

    -moz-animation:move-twink-back 200s linear infinite;
    -ms-animation:move-twink-back 200s linear infinite;
    -o-animation:move-twink-back 200s linear infinite;
    -webkit-animation:move-twink-back 200s linear infinite;
    animation:move-twink-back 200s linear infinite;
  }

  @keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
  }
}
</style>
