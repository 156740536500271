<template>
  <div class="chartBox">
    <dv-border-box-1 style="width: 100%;height: 100%;">
      <div class="bg-color-black pdng18">
        <div class="d-flex pt-2 pl-2 flexStart">
          <div class="flexStart">
            <span class="fs-xl text mx-2 chartTitle" style="margin-right: 10px;">企业性质</span>
            <dv-decoration-1 style="width:30%;height:1rem;"/>
          </div>
        </div>
        <div class="d-flex jc-center body-box feeContainer"
             style="width:100%;height:calc(100% - 30px);margin-top: 10px;">
          <div id="right_03" style="width: 100%; height: 100%;"></div>

        </div>
      </div>
    </dv-border-box-1>
  </div>
</template>
<script>
import echartMixins from "../js/resizeMixins";

export default {
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    setTimeout(function () {
      _this.draw('right_03')
    }, 600)
  },
  methods: {
    draw(name) {
      let _this = this
      let myChart = this.$echarts.init(document.getElementById(name));

      myChart.clear();

      myChart.showLoading();
      let index = -1;
      _this.timer = setInterval(function () {
        // 显示提示框
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: index + 1,
        });
        // 取消高亮指定的数据图形
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index,
        });
        // 高亮指定的数据图形
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: index + 1,
        });
        index++;
        if (index > 2) {
          index = -1;
        }
      }, 3000);

      myChart.on("click", function (e) {
        clearInterval(_this.timer);
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });

      myChart.on("mousemove", function (e) {
        clearInterval(_this.timer);
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      }); //---------------------------------------------鼠标移入静止播放
      myChart.on("mouseout", function (e) {
        clearInterval(_this.timer);
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        }); //鼠标移出后先把上次的高亮取消
        _this.timer = setInterval(function () {
          // 隐藏提示框
          myChart.dispatchAction({
            type: "hideTip",
            seriesIndex: 0,
            dataIndex: index,
          });
          // 显示提示框
          myChart.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: index + 1,
          });
          // 取消高亮指定的数据图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: index,
          });
          // 高亮指定的数据图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: index + 1,
          });
          index++;
          if (index > 2) {
            index = -1;
          }
        }, 2000);
      });
      myChart.hideLoading();

      let option = {
        // color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        color: ['#ee6666','#5470c6',
          '#73c0de','#ea7ccc', '#fac858', '#9a60b4',
           '#91cc75',
          '#3ba272', '#fc8452',  '#ea7ccc',

        ],
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            let zs_count = params.value?params.value:0
            return `<span style="color: #ff0;font-weight: 700;">${params.name}(人)</span>
<br/>
<span style="color: #fff">人数：${params.value}</span>`;

          },
          position:'right',
          confine: true,
        },
        series: [
          {
            name: '人数',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
                formatter: '{d}%'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: _this.list,
          }
        ]
      };
      myChart.setOption(option);

    },
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>
<style lang="less" type="text/less">
.feeContainer {
  .dv-capsule-chart {
    padding-bottom: 0;

    .capsule-container {
      flex: 0 0 60%;
    }
  }

  .unit-label {
    div {
      color: #0d1224;

    }
  }

  .unit-text {
    color: #0d1224;
    //color: pink;
  }
}
</style>
<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;
}
</style>
