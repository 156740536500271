<template>
    <div class="chartBox">
        <div class="flexBetweenUp" style="height: 50%;">
            <div class="flexStartUp" style="width: 35%;height: 100%;">
                <dv-decoration-10 style="width:65%;height:0.1rem;" />
                <dv-decoration-8 :color="['#568aea', '#000000']" style="width:30%;height:100%;" />
            </div>
            <div class="flexCenterBottom" style="width: 30%;height: 100%;">
                <span style="font-size: 1.5rem;white-space: nowrap;">{{$store.state.real_name}}毕业跟踪调查平台监管中心</span>
            </div>
            <div class="flexEndUp" style="width: 35%;height: 100%;">
                <dv-decoration-8 :reverse="true" :color="['#568aea', '#000000']"
                                 style="width:30%;height:100%;"/>
                <dv-decoration-10 style="width:65%;height:0.1rem; transform: rotateY(180deg);" />
            </div>
        </div>

        <div class="flexBetweenUp" style="height: 50%;">
            <div class="flexBetweenUp" style="width: 35%;height: 100%;">
                <div class="react-right ml-4 flexStart"
                     style="margin-left: 3.2%;background-color: #ffffff21;width: 60%">
                    <span class="react-before"></span>
                    <span class="text flexCenter" style="width: 100%;">
                       <el-select v-model="formData.plan_id" filterable
                                  @change="changePlan" size="small" placeholder="问卷计划">
                         <el-option v-for="item in planList"
                                    :key="item.id"
                                    :label="item.plan_name"
                                    :value="item.id">
                         </el-option>
                       </el-select>
                    </span>
                </div>
                <div class="react-right ml-3 flexStart" style="background-color: #ffffff21;width: 35%">
                    <span class="text colorBlue"style="width: 100%;">
                       <el-cascader v-if="!isShowPT" v-model="formData.class_id"
                                    :props="{label:'orgName',value:'orgId',emitPath:false}"
                                    :options="classList" size="small"
                                    :clearable="true"
                                    @change="handleChange"
                                    style="width: 85%;"
                                    placeholder="系部 / 年级 / 班级"></el-cascader>
                    </span>
                </div>
            </div>
            <div class="flexCenter" style="width: 30%;height: 100%;">
                <dv-decoration-6
                    class="title-bototm"
                    :reverse="true"
                    :color="['#50e3c2', '#67a1e5']"
                    style="width:60%;height:20%; overflow: hidden"
                />
            </div>
            <div class="flexBetweenUp" style="width: 35%;height: 100%;">
                <div class="react-left bg-color-blue mr-3 flexEnd"
                     style="background-color: #ffffff21;width: 35%">
                    <span class="text fw-b"></span>
                </div>
                <div class="react-left mr-4 flexEnd"
                     style="margin-right: 3.2%;background-color: #ffffff21;width: 60%">
                    <span class="react-after"></span>
                    <span class="text">{{curTime}}</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

	export default {
		data () {
			return {
				curTime:'',
				weeks: ["星期天","星期一","星期二","星期三","星期四","星期五","星期六"],
				timer:null,
        formData:{
          plan_id:''
        },
        classList:[],
        planList:[],
        isShowPT:false,
			};
		},
		mounted () {
			let _this = this;
			this.timer = setInterval(function() {
				_this.getTime()
			}, 1000);
			_this.getClass()
      _this.getPlan()
		},
		methods: {
      getPlan() {
        this.planList = []
        this.$http.post("api/by_ivst_plan_list", {page: 1, pagesize: 1000000,}).then((res) => {
          if (res.data && res.data.data && res.data.data.length > 0) {
            this.planList = res.data.data;
            this.formData.plan_id = res.data.data[0].id
            let obj = {
              plan_name:'平台数据',id:'pt'
            }
            this.planList.push(obj)
          }
        });
      },
      getClass() {
        this.$http.post("api/sys_office", {"parent_id": "0", pmid: "0"}).then((res) => {
			if(res.data[0].children){
				this.deleteEmptyGroup(res.data[0].children).then(r => {
				  this.classList = r
				})
			}
         

        });
      },
      // 处理没有children的分组
      deleteEmptyGroup(treeData) {
        return new Promise(resolve => {
          function traversal(data) {
            data.map(item => {
              for (let info in item) {
                if (item['children']) {
                  if (item['children'].length > 0) {
                    traversal(item['children'])
                  }
                  if (item['children'].length == 0) {
                    delete item['children']
                  }
                }
              }
            })
          }

          traversal(treeData)
          resolve(treeData)
        })
      },
      changePlan(e){
        if(e=='pt'){
          this.isShowPT = true
          this.$emit('showPT')
        }else {
          this.isShowPT = false
          this.$emit('showPlan',e)
        }
      },
      handleChange(e) {
        console.log(e)
        this.$emit('changeClass',e)
        // let name = this.$refs['cascaderClass'].getCheckedNodes()[0].pathLabels[2]
        // this.ruleForm.class_name = name
      },

      add0(value){
				return value<10 ? '0'+value : value;
			},
			getTime(){
				let date =  new Date(),
				year = date.getFullYear(),
				month = this.add0(date.getMonth() + 1),
				day = this.add0(date.getDate()),
				hours = this.add0(date.getHours()),
				minutes = this.add0(date.getMinutes()),
				seconds = this.add0(date.getSeconds()),
				week = date.getDay();
				this.curTime = `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds} ${this.weeks[week]}`
			}
		},
		beforeDestroy: function() {
			if (this.timer) {
				clearInterval(this.timer);
			}
		}

	};
</script>

<style scope lang="less" type="text/less">
    .chartBox {
        width: 100%;
        height: 100%;
        div{
            box-sizing: border-box;
        }
      .el-cascader{
        margin-left: -1rem;
        input{
          background-color: #FFFFFF21;
          border: 1px solid #FFFFFF21;
          color: #fff;
        }
      }
      .el-cascader__dropdown{
        background-color: transparent!important;
      }
      .el-select{
        margin-left: -1rem;
        input{
          background-color: #FFFFFF21;
          border: 1px solid #FFFFFF21;
          color: #fff;
        }
        .el-input__inner{
          background-color: transparent!important;
          //border: 0;
        }
      }
    }
</style>
