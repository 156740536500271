<template>
  <div class="chartBox">
    <dv-border-box-12 style="width: 100%;height: 100%;">
      <div class="bg-color-black pdng18">
        <div class="d-flex pt-2 pl-2 flexStart">
          <div class="flexStart">
            <span class="fs-xl text mx-2 chartTitle" style="margin-right: 10px;">{{isShowPT?'政治面貌':'对口率'}}</span>
            <dv-decoration-1 style="width:30%;height:1rem;"/>
          </div>
        </div>
        <div class="d-flex jc-center body-box feeContainer" style="width:100%;height:calc(100% - 30px);margin-top: 10px;">
          <div class="flexAround" style="width:100%;height: 100%;">
            <div class="flexCloumnBetween" v-for="(item,index) in zhengzhiList" :key="index" style="width:20%;height: 90%;">
              <div style="width:100%;height:0;padding-bottom:100%;position:relative;">
                <dv-water-level-pond :config="item" style="width:100%;height: 100%;position:absolute;" />
              </div>
              <div style="color: #9ECEF1;">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-12>
  </div>
</template>
<script>
import echartMixins from "../js/resizeMixins";

export default {
  data() {
    return {
      zhengzhiList:[]
    };
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
    isShowPT:Boolean,
  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    setTimeout(function (){
      let arr =  JSON.parse(JSON.stringify(_this.list))
      _this.zhengzhiList = arr
      // console.log(47,arr)
    },400)
  },
  methods: {},
  destroyed() {
    window.onresize = null;
  }
};
</script>
<style lang="less" type="text/less">
.feeContainer {
  .dv-capsule-chart {
    padding-bottom: 0;

    .capsule-container {
      flex: 0 0 60%;
    }
  }

  .unit-label {
    div {
      color: #0d1224;

    }
  }

  .unit-text {
    color: #0d1224;
    //color: pink;
  }
}
</style>
<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;
}
</style>
