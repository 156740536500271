<template>
  <div class="chartBox">
    <dv-border-box-1 :dur="15" style="width: 100%;height: 100%;">
      <div class="bg-color-black pdng18">
        <div class="d-flex pt-2 pl-2 flexStart">
          <div class="flexStart">
            <span class="fs-xl text mx-2 chartTitle" style="margin-right: 10px;">就业区域/人</span>
            <dv-decoration-1 style="width:30%;height:1rem;"/>
          </div>
        </div>
        <div class="d-flex jc-center body-box feeContainer" style="height:calc(100% - 30px);margin-top: 10px;">
          <div class="flexBetween" style="width: 100%;height: 100%;">
            <div id="left_03_pie" style="width: 30%; height: 100%;"></div>
            <div id="left_03_bar" style="width: 68%; height: 100%;"></div>
          </div>

        </div>
      </div>

    </dv-border-box-1>
  </div>
</template>

<script>
import echartMixins from "../js/resizeMixins";

export default {
  data() {
    return {
      chart: null,
      citys: [],
      cityLabels: [],
      barTitle:'本地就业'
    };
  },
  watch:{
    left_03_pie(val, oldVal){//普通的watch监听
      this.drawPie('left_03_pie')
    },
    left_03_jiangsu(val, oldVal){//普通的watch监听
      let arr = JSON.parse(JSON.stringify(this.left_03_jiangsu))
      this.citys = arr
      this.drawBar('left_03_bar');
    },
    left_03_noJiangsu(val, oldVal){//普通的watch监听
      let arr = JSON.parse(JSON.stringify(this.left_03_noJiangsu))
      this.citys = arr
      this.drawBar('left_03_bar');
    },
  },
  props: {
    left_03_pie:{
      type: Array,
      default: [],
    },
    left_03_jiangsu: {
      type: Array,
      default: [],
    },
    left_03_noJiangsu: {
      type: Array,
      default: [],
    },

  },
  mixins: [echartMixins],
  mounted() {
    let _this = this
    setTimeout(function () {
      let arr = JSON.parse(JSON.stringify(_this.left_03_jiangsu))
      _this.citys = arr
      _this.drawPie('left_03_pie')
      _this.drawBar('left_03_bar');
    }, 600)
  },
  methods: {
    getData() {
      let _this = this
      return new Promise(resolve => {
        let arr = []
        _this.citys.map(a => {
          arr.push(a.area_name)
        })
        _this.cityLabels = arr
        resolve(_this.citys)
      })
    },
    drawPie(name) {
      let _this = this
      let myChart = this.$echarts.init(document.getElementById(name));
      let option = {
        color: ['#73c0de', '#91cc75', '#fac858', '#ee6666',
          '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc',
          '#5470c6'
        ],
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            let zs_count = params.value?params.value:0
            return `<span style="color: #ff0;font-weight: 700;">${params.name}(人)</span>
<br/>
<span style="color: #fff">人数：${params.value}</span>`;

          },
          position:'right',
          confine: true,
        },
        series: [
          {
            type: 'pie',

            center: ['50%', '50%'],
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,

            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: _this.left_03_pie,
          }
        ]
      };
      myChart.setOption(option);
      myChart.on("click", function (e) {
        console.log(e.name)
        if(e.name=='本地就业'){
          _this.citys = _this.left_03_jiangsu
          _this.barTitle='本地就业'
          _this.drawBar('left_03_bar')
        }else {
          _this.citys = _this.left_03_noJiangsu
          console.log(_this.left_03_noJiangsu)
          _this.barTitle='外地就业'
          _this.drawBar('left_03_bar')
        }
      });
    },

    drawBar(name) {
      let _this = this
      this.getData().then(result => {
        let myChart = this.$echarts.init(document.getElementById(name));
        myChart.clear();

        let category = _this.cityLabels, arrx = [];
        let lineData = [], arryp = []
        let barData = [], arryt = [];
        let rateData = [];
        if (result && result.length > 0) {
          result.map(r => {
            arrx.push(r.area_name)
            arryp.push(r.plan_num)
            arryt.push(r.zs_count)
          })
          category = [...arrx]
          lineData = [...arryp]
          barData = [...arryt]
        }
        for (let i = 0; i < lineData.length; i++) {
          let rate = 0
          if (lineData[i] == 0) {
            rate = 0
          } else {
            rate = barData[i] / lineData[i];
          }

          rateData[i] = rate
        }

        let option = {
          // animation: false,
          title : {        text:'',        subtext: _this.barTitle   },
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            },
          },

          grid: {
            x: 50,
            y: 55,
          },
          xAxis: {
            data: _this.cityLabels,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              interval: 0,
              // rotate: 60,
            },
          },
          yAxis: [
            {
              splitLine: {show: false},
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },

              axisLabel: {
                formatter: "{value} "
              }
            },
            {
              splitLine: {show: false},
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },
              axisLabel: {
                formatter: "{value} "
              }
            }
          ],
          series: [
            {
              name: "人数",
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: "#19F7FF"},
                    {offset: 1, color: "#0799F4"}
                  ])
                }
              },
              data: barData
            },

          ]
        };
        myChart.setOption(option);
      })

    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;
}
</style>
