<template>
  <div class="chartBox flexCenter">
    <div class="d-flex jc-center body-box feeContainer" style="width:100%;height:55%;">
      <div class="flexAround" style="width:100%;height: 100%;">
        <div class="flexCloumnBetween" style="width:45%;height: 100%;align-items: flex-start;">
          <div class="flexStart" style="color: #AADDFF;font-size: 16px;margin-right: 20px;">
            <dv-decoration-12 style="width:16px;height:16px;" />
            <span style="margin-left: 6px;">问卷参与人数(人)</span>
            </div>
          <dv-border-box-10 :dur="5" style="width: 100%;height:  calc( 100% - 40px );">
            <div class="flexCenter" style="width: 100%;height: 100%;font-size: 30px;">{{ middle_01_planL }}</div>
          </dv-border-box-10>
        </div>

        <div class="flexCloumnBetween" style="width:45%;height: 100%;align-items: flex-start;">
          <div class="flexStart" style="color: #AADDFF;font-size: 16px;margin-right: 20px;">
            <dv-decoration-12 style="width:16px;height:16px;" />
            <span style="margin-left: 6px;">问卷调查满意度</span>
            </div>
          <dv-border-box-10 :reverse="true" :dur="8" style="width: 100%;height:  calc( 100% - 40px );">
            <div class="flexCenter" style="width: 100%;height: 100%;font-size: 30px;">{{ middle_01_planR }}</div>
          </dv-border-box-10>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import echartMixins from "../js/resizeMixins";

export default {
  data() {
    return {};
  },
  props: {
    middle_01_planL: {
      type: String,
      default: 0,
    },
    middle_01_planR: {
      type: String,
      default: 0,
    },
  },
  mixins: [echartMixins],
  mounted() {
  },
  methods: {},
  destroyed() {
    window.onresize = null;
  }
};
</script>
<style lang="less" type="text/less">
.feeContainer {
  .dv-capsule-chart {
    padding-bottom: 0;

    .capsule-container {
      flex: 0 0 60%;
    }
  }

  .unit-label {
    div {
      color: #0d1224;

    }
  }

  .unit-text {
    color: #0d1224;
    //color: pink;
  }
}
</style>
<style scope lang="less" type="text/less">
.chartBox {
  width: 100%;
  height: 100%;
}
</style>
